import React, { useEffect, useRef } from 'react';
import './App.css';
import './assets/scss/style.css';
import About from './components/About';
import Blog from './components/Blog';
import Main from "./components/Main";
import { createBrowserRouter , RouterProvider } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Career from './components/Career';
import 'aos/dist/aos.css';

function App() {

  const router = createBrowserRouter([
    {
      path: "/" ,
      element: <><Navbar/><Main /><Footer/></>
    },
    {
      path: "/about" ,
      element: <><Navbar/><About /><Footer/></>
    },
    {
      path: "/blog" ,
      element: <><Navbar/><Blog /><Footer/></>
    },
    {
      path: "/career" ,
      element: <><Navbar/><Career /><Footer/></>
    },
  ])

  const cursorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (cursorRef.current) {
        cursorRef.current.style.top = `${e.pageY - 25}px`;
        cursorRef.current.style.left = `${e.pageX - 25}px`;
      }
    };

    const handleClick = () => {
      if (cursorRef.current) {
        console.log("%c Click...!!!", "font-size: 20px; color:mediumspringgreen;");
        cursorRef.current.classList.add('cursor--expand');
        console.log(cursorRef.current.classList);

        setTimeout(() => {
          if (cursorRef.current) {
            cursorRef.current.classList.remove('cursor--expand');
          }
        }, 500);
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('click', handleClick);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <div className="App">
       <div className="App home-container">
       <div className="cursor" ref={cursorRef}></div>
        <RouterProvider router={router} /> 
    </div>
    </div>
  );
}

export default App;