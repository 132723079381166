import React from 'react'
import images29 from '../assets/images/29.png'
import images30 from '../assets/images/30.png'
import AOS from 'aos';
import 'aos/dist/aos.css';

function About() {
    AOS.init();
  return (
   <>
   <div className="container-fluid">
    <div className="row about-us">
        <div className="col-12">
            <div data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine">
                <h5 className="main-title text-center flipInY mt-0" data-animated="true"><span>About us</span></h5>
            </div>
        </div>
        <div className="col-12 aboutus-Work">
            <div className="row">
                <div className="col-xxl-6 col-xl-6 aboutus-img" data-aos="zoom-out-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <img src={images29} className="img-fluid" />
                </div>
                <div className="col-xxl-6 col-xl-6 aboutus-title">
                    <div data-aos="zoom-out-left"
     data-aos-offset="300"
     data-aos-easing="ease-in-sine">
                        <h5>Proven Provider of Software Solutions Tailored to Your Business</h5>
                    </div>
                    <p data-aos="zoom-out-left"
     data-aos-offset="300"
     data-aos-easing="ease-in-sine">
                        Certainly! Here's a paragraph based on your prompt:

                        Looking for software solutions tailored precisely to meet your business needs? Look no further. Our track record as a proven provider of bespoke software solutions speaks for itself. We understand that every business is unique, which is why we specialize in crafting software that fits your specific requirements like a glove. Whether you're seeking streamlined processes, enhanced efficiency, or innovative solutions to propel your business forward, we have the expertise and experience to deliver. With our tailored approach, you can expect nothing less than software solutions finely tuned to optimize your operations and drive your success.
                    </p>
                </div>
            </div>
        </div>
        <div className="col-12 aboutus-text">
            <h6 data-aos="fade-right"
     data-aos-offset="300"
     data-aos-easing="ease-in-sine">Our Development Process :</h6>
            <p data-aos="fade-right"
     data-aos-offset="300"
     data-aos-easing="ease-in-sine">
                Our Development Process:
                At our core, we're more than just developers; we're architects of digital solutions. Our development process is meticulously designed to transform your vision into reality. It begins with a comprehensive analysis phase, where we delve deep into understanding your requirements and objectives. From there, we craft a detailed plan, laying the groundwork for the journey ahead. Our development phase is characterized by creativity, scalability, and flexibility, ensuring that your solution not only meets but exceeds your expectations. Rigorous testing follows, guaranteeing the quality and performance of every component. Finally, with precision and care, we launch your solution, ready to make its mark in the digital landscape.
            </p>
            <h6 data-aos="fade-right"
     data-aos-offset="300"
     data-aos-easing="ease-in-sine">Origin Story :</h6>
            <p data-aos="fade-right"
     data-aos-offset="300"
     data-aos-easing="ease-in-sine">
                With a combined experience of over 15 years, the founders of AcceTech bring a wealth of expertise to the table, driving the company's remarkable journey of five successful years.
                Our seasoned management team boasts extensive experience across diverse technologies, providing us with a unique advantage in comprehensively understanding our clients'
                requirements. We excel in translating these needs into refined and effective specifications, paving the way for the delivery of top-notch solutions.
                At AcceTech, our commitment to quality and innovation remains unwavering, ensuring that each project we undertake achieves excellence and client satisfaction.
            </p>
            <p data-aos="fade-right"
     data-aos-offset="300"  
     data-aos-easing="ease-in-sine">
                To pave an uninterrupted path for your burgeoning online enterprise, AcceTech prioritizes a "customer-first" ethos. With AcceTech at their side, modern companies can streamline operations, enhance efficiency, offer value-added services, and confront emerging challenges head-on. Our dedication lies in furnishing superior software products and solutions through a meticulously refined and mature Software Development Process, firmly rooted in the Rational Unified Process framework. At AcceTech, we stand committed to empowering businesses with innovative technology solutions, ensuring their sustained growth and success in the digital landscape.
            </p>
        </div>
        <div className="col-12">
            <div >
                <h5 className="main-title text-center stay-one-step" data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" ><span>Stay one step ahead </span> <br />in your industry with our seamless solutions.</h5>
            </div>
        </div>
        <div className="col-12 you-outsource">
            <div className="row">
                <div className="col-xxl-6 col-xl-6 easy-text">
                    <h6 data-aos="zoom-out-right" data-aos-offset="300" data-aos-easing="ease-in-sine">We grasp your requirements and seamlessly translate them into digital solutions.</h6>
                    <p data-aos="zoom-out-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        Leveraging our in-depth comprehension of your specific needs, we expertly navigate the intricate landscape of digital solutions, transforming your vision into innovative and seamlessly integrated technological realities.
                    </p>
                </div>
                <div className="col-xxl-6 col-xl-6 easy-img" data-aos="zoom-out-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <img src={images30} alt="" className="img-fluid"  />
                </div>
            </div>
        </div>
    </div>
   </div>
   </>
  )
}

export default About
