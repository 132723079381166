import React from 'react'
import Logo from '../assets/images/logo.png';

function Footer() {
  return (
    <footer className="row footer">
    <div className="col-xxl-4 col-xl-3 social-icons align-items-start">
        <a className="footer-brand">
            <img src={Logo} />
            <p>ACCETECH</p>
            <h6>Innovate. Integrate. Elavat</h6>
        </a>
        <div>
            <i className="ti ti-brand-instagram"></i>
            <i className="ti ti-brand-facebook"></i>
            <i className="ti ti-brand-twitter"></i>
            <i className="ti ti-brand-linkedin"></i>
        </div>
    </div>
    <div className="col-xxl-8 col-xl-8 footer-ui">
        <div className="row">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 d-flex justify-content-start">
                <div className="footer-list">
                    <p>IMPORTANT LINK</p>
                    <ul>
                        <li>Home</li>
                        <li>Services</li>
                        <li>About us</li>
                        <li>portfolio</li>
                        <li>Career</li>
                    </ul>
                </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 d-flex justify-content-start">
                <div className="footer-list">
                    <p>OUR SERVICES</p>
                    <ul>
                        <li>Web Development</li>
                        <li>App Development</li>
                        <li>Software Development</li>
                        <li>UI/UX Design</li>
                        <li>Digital Marketing</li>
                    </ul>
                </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 d-flex justify-content-start">
                <div className="footer-list">
                    <p>Head Office</p>
                    <ul>
                        <li>
                            UNIT NO. ASO-230, TOWER-SOUTH, ASTRATOWERS, NEW TOWN, KOLKATA,
                            North 24Parganas, Pincode - 700157.
                        </li>
                        <li>salessupport@acceglobalgroup.com</li>
                        {/* <li>acceglobalgroup.com</li> */}
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
  )
}

export default Footer
