import React from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

function Blog() {
    AOS.init();
  return (
    <>
      <div className="container-fluid">
    <div className="row">
        <div className="col-12 main-blog">
            <div data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine">
                <h5 className="main-title text-center"><span>Softwere</span> development <br /> Blog Posts</h5>
            </div>
        </div>
        <div className="col-12 development-text" data-aos="zoom-out-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <p>We share our experience and expert knowledge in the field of software development and product design. The following articles are written by software development experts from CrustLab based on their knowledge gained while working on our clients’ projects. We hope that they will help you make key decisions for your business and in your daily work. If you have any questions or comments, please contact us.</p>
        </div>
        <div className="col-12 blogs">
            <div className="row">
                <div className="col-xxl-4 col-xl-6 mt-3 mb-3">
                    <div className="blog-posts" data-aos="fade-right" data-aos-duration="2000">
                        <div >
                            <h5>
                                Benefits of Push
                                Nofifications
                            </h5>
                        </div>
                        <p >n a world of ever-changing marketing trends, companies bend over backward to distinguish their offers and find their way to reach the target audience. It is exactly where marketing with push notifications comes in handy.</p>
                        <div className="w-100">
                            <a className="btn" href="#">
                                <span>Read More</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-4 col-xl-6 mt-3 mb-3">
                    <div className="blog-posts" data-aos="fade-right" data-aos-duration="2000">
                        <div>
                            <h5>
                                Benefits of Push
                                Nofifications
                            </h5>
                        </div>
                        <p>n a world of ever-changing marketing trends, companies bend over backward to distinguish their offers and find their way to reach the target audience. It is exactly where marketing with push notifications comes in handy.</p>
                        <div className="w-100">
                            <a className="btn" href="#">
                                <span>Read More</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-4 col-xl-6 mt-3 mb-3">
                    <div className="blog-posts" data-aos="fade-right" data-aos-duration="2000">
                        <div>
                            <h5>
                                Benefits of Push
                                Nofifications
                            </h5>
                        </div>
                        <p>n a world of ever-changing marketing trends, companies bend over backward to distinguish their offers and find their way to reach the target audience. It is exactly where marketing with push notifications comes in handy.</p>
                        <div className="w-100">
                            <a className="btn" href="#">
                                <span>Read More</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-4 col-xl-6 mt-3 mb-3">
                    <div className="blog-posts" data-aos="fade-right" data-aos-duration="2000">
                        <div>
                            <h5>
                                Benefits of Push
                                Nofifications
                            </h5>
                        </div>
                        <p>n a world of ever-changing marketing trends, companies bend over backward to distinguish their offers and find their way to reach the target audience. It is exactly where marketing with push notifications comes in handy.</p>
                        <div className="w-100">
                            <a className="btn" href="#">
                                <span>Read More</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-4 col-xl-6 mt-3 mb-3">
                    <div className="blog-posts" data-aos="fade-right" data-aos-duration="2000">
                        <div>
                            <h5>
                                Benefits of Push
                                Nofifications
                            </h5>
                        </div>
                        <p>n a world of ever-changing marketing trends, companies bend over backward to distinguish their offers and find their way to reach the target audience. It is exactly where marketing with push notifications comes in handy.</p>
                        <div className="w-100">
                            <a className="btn" href="#">
                                <span>Read More</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-4 col-xl-6 mt-3 mb-3">
                    <div className="blog-posts" data-aos="fade-right" data-aos-duration="2000">
                        <div>
                            <h5>
                                Benefits of Push
                                Nofifications
                            </h5>
                        </div>
                        <p>n a world of ever-changing marketing trends, companies bend over backward to distinguish their offers and find their way to reach the target audience. It is exactly where marketing with push notifications comes in handy.</p>
                        <div className="w-100">
                            <a className="btn" href="#">
                                <span>Read More</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-12 pagination" data-aos="fade-right"
     data-aos-offset="300"
     data-aos-easing="ease-in-sine">
            <div className="pagination-numbar">
                <h6><i className="ti ti-chevron-left"></i> Previous</h6>
                <p className="active">1</p>
                <p>2</p>
                <p>3</p>
                <p>4</p>
                <h6>Next <i className="ti ti-chevron-right chevron-right"></i></h6>
            </div>
        </div>
    </div>
    </div>
    </>
  )
}

export default Blog
