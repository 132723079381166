import React, { useEffect, useState } from 'react';
import '../assets/scss/style.css';
import Logo from '../assets/images/logo.png';
import { HiMenuAlt3 } from "react-icons/hi";
import { Link, NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Ensure Bootstrap JS is imported
import { TiHomeOutline } from "react-icons/ti";
import { TfiLayoutListThumb } from "react-icons/tfi";
// import { FiGrid } from "react-icons/fi";
import { LuUserCheck } from "react-icons/lu";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      console.log('Window scrollY:', window.scrollY);
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <header className={`main-navbar main-header ${scrolled ? 'scrolled' : ''}`} id="navbar">
        <nav className="navbar border-body" id="navbar-toggle" data-bs-theme="dark">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center w-100">
              <a className="navbar-brand">
                <img src={Logo} alt="Logo" />
                <div>
                  <p>ACCETECH</p>
                  <h6 className="m-0">Innovate. Integrate. Elevate</h6>
                </div>
              </a>
              <ul className="navbar-ul">
                <li><NavLink className={(e) => {return e.isActive ? "active" : ""}} to="/">Home</NavLink></li>
                <li><NavLink className={(e) => {return e.isActive ? "active" : ""}} to="/about">About</NavLink></li>
                {/* <li><NavLink className={(e) => {return e.isActive ? "active" : ""}} to="/blog">Blogs</NavLink></li> */}
                <li><NavLink className={(e) => {return e.isActive ? "active" : ""}} to="/career">Career</NavLink></li>
              </ul>
              <button className="btn-menu" type="button" data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop" aria-controls="staticBackdrop">
                <HiMenuAlt3 className='menu-icon' />
              </button>
            </div>
          </div>
        </nav>
      </header>

      <div className="offcanvas layout-sidebar offcanvas-start" data-bs-backdrop="static" id="staticBackdrop" aria-labelledby="staticBackdropLabel">
        <div className="offcanvas-header">
          <a className="offcanvas-brand">
            <img src={Logo} alt="Logo" />
            <div>
              <p>ACCETECH</p>
              <h6 className="m-0">Innovate. Integrate. Elevate</h6>
            </div>
          </a>
          <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body p-0">
          <ul className="sidebar-body">
            <li><NavLink className={(e) => {return e.isActive ? "active" : ""}} to="/"><TiHomeOutline /> Home</NavLink></li>
            <li><NavLink className={(e) => {return e.isActive ? "active" : ""}} to="/about"><TfiLayoutListThumb /> About</NavLink></li>
            {/* <li><NavLink className={(e) => {return e.isActive ? "active" : ""}} to="/blog"><i className="ti ti-layout-grid-add"></i> Blogs</NavLink></li> */}
            <li><NavLink className={(e) => {return e.isActive ? "active" : ""}} to="/career"><LuUserCheck /> Career</NavLink></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
